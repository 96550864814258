export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Sign In',
            to: '/login',
            icon: 'cil-speedometer'
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Sign Up',
            to: '/register',
            icon: 'cil-speedometer'
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Partner with Us',
            to: '/restaurant-signup',
            icon: 'cil-speedometer'
        },
        
    ]
}]