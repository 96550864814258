<template>
<CSidebar :overlaid="true" :aside="true" class="sidebar-bg" :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="" to="/">
        <!-- <CImg class="logo-size pt-3" src="img/avatars/3.jpg"></CImg> -->
    </CSidebarBrand>

    <CSidebarNav>
        <CSidebarNavTitle v-if="customer_access_token">Hi {{ user_name }}</CSidebarNavTitle>
        <!-- <CSidebarNavItem v-if="!customer_access_token" name="Sign In" to="/login" :icon="{
          name: 'cil-pencil',
        }" />
        <CSidebarNavItem v-if="!customer_access_token" name="Sign Up" to="/register" :icon="{
          name: 'cil-pencil',
          customClasses: 'c-sidebar-nav-icon c-icon c-icon-1xl',
        }" />

        <CSidebarNavItem v-if="customer_access_token" name="My Profile" to="/my-profile" :icon="{
          name: 'cil-user',
          customClasses: 'c-sidebar-nav-icon c-icon c-icon-1xl',
        }" />
        <CSidebarNavItem v-if="customer_access_token" name="My Orders" to="/my-orders" :icon="{
          name: 'cil-list',
          customClasses: 'c-sidebar-nav-icon c-icon c-icon-1xl',
        }" />
        <CSidebarNavItem name="Partner with Us" to="/restaurant-signup" :icon="{
          name: 'cil-settings',
          customClasses: 'c-sidebar-nav-icon c-icon c-icon-1xl',
        }" /> -->

        <li @click="activeMenu(1)" class="c-sidebar-nav-item" v-if="!customer_access_token">
            <a href="#/login" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 1 ? 'c-active' : '', '']">
                <svg aria-hidden="true" width="19" focusable="false" data-prefix="fas" data-icon="right-to-bracket" class="svg-inline--fa fa-right-to-bracket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M344.7 238.5l-144.1-136C193.7 95.97 183.4 94.17 174.6 97.95C165.8 101.8 160.1 110.4 160.1 120V192H32.02C14.33 192 0 206.3 0 224v64c0 17.68 
         14.33 32 32.02 32h128.1v72c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C354.3 264.4 354.3 247.6 344.7 238.5zM416
          32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02
           0 96-42.98 96-96V128C512 74.98 469 32 416 32z"></path>
                </svg> <span class="ml-4">Sign In</span>
            </a>
        </li>
        <li @click="activeMenu(2)" class="c-sidebar-nav-item" v-if="!customer_access_token">
            <a href="#/register" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 2 ? 'c-active' : '', '']">
               <svg aria-hidden="true" width="20" focusable="false" data-prefix="fas" data-icon="user-plus" class="svg-inline--fa fa-user-plus" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 
                304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 
                10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z">
                </path></svg> <span class="ml-4">Sign Up</span>
            </a>
        </li>

        <li @click="activeMenu(3)" class="c-sidebar-nav-item" v-if="customer_access_token">
            <a href="#/my-profile" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 3 ? 'c-active' : '', '']">
               <svg aria-hidden="true" width="13" focusable="false" data-prefix="fas" data-icon="id-badge" class="svg-inline--fa fa-id-badge" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
               <path fill="currentColor" d="M336 0h-288C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48v-416C384 21.49 362.5 0 336 0zM192 160c35.35 0 64 28.65 64 64s-28.65
                64-64 64S128 259.3 128 224S156.7 160 192 160zM288 416H96c-8.836 0-16-7.164-16-16C80 355.8 115.8 320 160 320h64c44.18 0 80 35.82 80 80C304 408.8 296.8 416 288 416zM240 96h-96C135.2 96
                 128 88.84 128 80S135.2 64 144 64h96C248.8 64 256 71.16 256 80S248.8 96 240 96z"></path></svg>
                <span class="ml-4">My Profile</span>
            </a>
        </li>

        <li @click="activeMenu(4)" class="c-sidebar-nav-item" v-if="customer_access_token">
            <a href="#/my-orders" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 4 ? 'c-active' : '', '']">
               <svg aria-hidden="true" width="15" focusable="false" data-prefix="fas" data-icon="cart-plus" class="svg-inline--fa fa-cart-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
               <path fill="currentColor" d="M176 416c-26.51 0-47.1 21.49-47.1 48S149.5 512 176 512s47.1-21.49 47.1-48S202.5 416 176 416zM569.5 44.73C563.4 36.64 554.1 32 543.1 32H121.1L119.6 19.51C117.4
                8.191 107.5 0 96 0H23.1C10.75 0 0 10.75 0 23.1S10.75 48 23.1 48h52.14l60.28 316.5C138.6 375.8 148.5 384 160 384H488c13.25 0 24-10.75 24-23.1C512 346.7 501.3 336 488 336H179.9L170.7 288h318.4c14.29 0
                 26.84-9.47 30.77-23.21l54.86-191.1C577.5 63.05 575.6 52.83 569.5 44.73zM416 160c0 11.31-9.256 20.57-20.57 20.57h-38.86v38.85C356.6 230.7 347.3 240 336 240s-20.57-9.258-20.57-20.57V180.6H276.6C265.3 
                 180.6 256 171.3 256 160s9.256-20.57 20.57-20.57h38.86V100.6c0-11.31 9.258-20.57 20.57-20.57s20.57 9.256 20.57 20.57v38.85h38.86C406.7 139.4 416 148.7 416 160zM463.1 416c-26.51 0-47.1 21.49-47.1 48s21.49
                  48 47.1 48s47.1-21.49 47.1-48S490.5 416 463.1 416z"></path></svg>
                <span class="ml-4">My Orders</span>
            </a>
        </li>

        <li @click="activeMenu(5)" class="c-sidebar-nav-item" v-if="customer_access_token">
            <a href="#/my-reservations" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 5 ? 'c-active' : '', '']">
               <svg aria-hidden="true" width="15" focusable="false" data-prefix="fas" data-icon="restroom" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-restroom"><path fill="currentColor" d="M128 128c35.3 0 64-28.7 64-64S163.3 0 128 0 64 28.7 64 64s28.7 64 64 64zm384 0c35.3 0 64-28.7 64-64S547.3 0 512 0s-64 28.7-64 64 28.7 64 64 64zm127.3 226.5l-45.6-185.8c-3.3-13.5-15.5-23-29.8-24.2-15 9.7-32.8 15.5-52 15.5-19.2 0-37-5.8-52-15.5-14.3 1.2-26.5 10.7-29.8 24.2l-45.6 185.8C381 369.6 393 384 409.2 384H464v104c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V384h54.8c16.2 0 28.2-14.4 24.5-29.5zM336 0h-32c-8.8 0-16 7.2-16 16v480c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zM180.1 144.4c-15 9.8-32.9 15.6-52.1 15.6-19.2 0-37.1-5.8-52.1-15.6C51.3 146.5 32 166.9 32 192v136c0 13.3 10.7 24 24 24h8v136c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V352h8c13.3 0 24-10.7 24-24V192c0-25.1-19.3-45.5-43.9-47.6z" class=""></path></svg>
                <span class="ml-4">My Reservations</span>
            </a>
        </li>

        <li @click="activeMenu(6)" class="c-sidebar-nav-item">
            <a href="#/restaurant-signup" class="router-link-exact-active c-sidebar-nav-link" v-bind:class="[active_menu == 6 ? 'c-active' : '', '']">
               <svg aria-hidden="true"  width="20" focusable="false" data-prefix="fas" data-icon="handshake" class="svg-inline--fa fa-handshake" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
               <path fill="currentColor" d="M0 383.9l64 .0404c17.75 0 32-14.29 32-32.03V128.3L0 128.3V383.9zM48 320.1c8.75 0 16 7.118 16 15.99c0 8.742-7.25 15.99-16 15.99S32 344.8 32 336.1C32 327.2 39.25 320.1
                48 320.1zM348.8 64c-7.941 0-15.66 2.969-21.52 8.328L228.9 162.3C228.8 162.5 228.8 162.7 228.6 162.7C212 178.3 212.3 203.2 226.5 218.7c12.75 13.1 39.38 17.62 56.13 2.75C282.8 221.3 282.9 221.3 283
                 221.2l79.88-73.1c6.5-5.871 16.75-5.496 22.62 1c6 6.496 5.5 16.62-1 22.62l-26.12 23.87L504 313.7c2.875 2.496 5.5 4.996 7.875 7.742V127.1c-40.98-40.96-96.48-63.88-154.4-63.88L348.8 64zM334.6 217.4l-30 
                 27.49c-29.75 27.11-75.25 24.49-101.8-4.371C176 211.2 178.1 165.7 207.3 138.9L289.1 64H282.5C224.7 64 169.1 87.08 128.2 127.9L128 351.8l18.25 .0369l90.5 81.82c27.5 22.37 67.75 18.12 90-9.246l18.12 15.24c15.88 
                 12.1 39.38 10.5 52.38-5.371l31.38-38.6l5.374 4.498c13.75 11 33.88 9.002 45-4.748l9.538-11.78c11.12-13.75 9.036-33.78-4.694-44.93L334.6 217.4zM544 128.4v223.6c0 17.62 14.25 32.05 31.1 32.05L640 384V128.1L544 
                 128.4zM592 352c-8.75 0-16-7.246-16-15.99c0-8.875 7.25-15.99 16-15.99S608 327.2 608 336.1C608 344.8 600.8 352 592 352z"></path></svg>
                <span class="ml-4">Partner with Us</span>
            </a>
        </li>

        <li @click="logout" class="c-sidebar-nav-item" v-if="customer_access_token">
            <a class="router-link-exact-active c-sidebar-nav-link">
               <svg aria-hidden="true" width="18"  focusable="false" data-prefix="fas" data-icon="right-from-bracket" class="svg-inline--fa fa-right-from-bracket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
               <path fill="currentColor" d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0
                128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918
                 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z"></path></svg> <span class="ml-4">Sign Out</span>
            </a>
        </li>
    </CSidebarNav>

    <CSidebarClose class="d-md-down-none" @click.native="$store.commit('set', ['sidebarShow', false])" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
</CSidebar>
</template>

<script>
import nav from "./_nav";
import {
    mapState,
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "TheSidebar",
    nav,
    computed: {
        ...mapState("user", ["user_name", "customer_access_token"]),
        show() {
            return this.$store.state.sidebarShow;
        },
        minimize() {
            return this.$store.state.sidebarMinimize;
        },
        computedSidebar() {
            return [{
                _name: "CSidebarNav",
                _children: this.currentItems,
            }, ];
        },
        currentItems() {
            let new_navbar = [];
            this.allItemArray.forEach((value, index) => {
                if (!this.customer_access_token) {
                    new_navbar = this.allItemArray.filter((item) => {
                        return !item.logged_required;
                    });
                } else {
                    new_navbar = this.allItemArray.filter((item) => {
                        return item.logged_required;
                    });
                }
            });
            return new_navbar;
        },
    },
    mounted() {
        this.$root.$on("toggle-sidebar", () => {
            const sidebarOpened = this.show === true || this.show === "responsive";
            this.show = sidebarOpened ? false : "responsive";
        });
        this.$root.$on("toggle-sidebar-mobile", () => {
            const sidebarClosed = this.show === "responsive" || this.show === false;
            this.show = sidebarClosed ? true : "responsive";
        });
        let self = this;
    },
    methods: {
        ...mapActions("user", ["destroyToken"]),
        async logout() {
            await this.destroyToken();
        },
        activeMenu(activeMenuId) {
            this.active_menu = activeMenuId;
        }
    },
    data() {
        return {
            active_menu: "",
            allItemArray: [{
                    _name: "CSidebarNavItem",
                    name: "Sign In",
                    to: "/login",
                    icon: "cil-speedometer",
                    logged_required: false,
                },
                {
                    _name: "CSidebarNavItem",
                    name: "Sign Up",
                    to: "/register",
                    icon: "cil-speedometer",
                    logged_required: false,
                },
                {
                    _name: "CSidebarNavItem",
                    name: "Partner with Us",
                    to: "/restaurant-signup",
                    icon: "cil-speedometer",
                    logged_required: false,
                },
                {
                    _name: "CSidebarNavItem",
                    name: "Sign Out",
                    to: "/restaurant-signup",
                    icon: "cil-speedometer",
                    logged_required: true,
                },
            ],
        };
    },
};
</script>

<style scoped>
.logo-size {
    width: 80px;
    height: auto;
}

.sidebar-bg {
    background-color: #100b33;
}
</style>
