<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader />
 
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>

      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import commonMixin from "@/mixins/common";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  mounted() {
    if(localStorage.getItem("customer_access_token")){
        this.checkAccessToeknIsValid();
    }
  },
  mixins: [commonMixin],
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
